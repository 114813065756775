import { Form } from 'src/models/form';

export interface FormAnswer {
  formQuestionId: number;
  value?: any;
  comment?: string;
  id?: number
  answerSetId?: number
}

export interface AnswerSet {
  formId: number;
  answers: FormAnswer[];
  userId?: number;
  assignmentId?: string | number;
  studentUserId?: string | number;
  subjectId?: string | number;
  isMidSemester?: boolean;
  createdAt?: string;
  id?: number;
  updatedAt?: string
}

export class FormAnswers {
  private answers: Map<number, FormAnswer>;

  constructor(private readonly form: Form, fromAnswerSet?: AnswerSet) {
    this.answers = new Map<number, FormAnswer>();

    if (!fromAnswerSet || form.id === fromAnswerSet?.formId) {
      form.groups?.forEach(group => {
        group.questions?.forEach(question => {
          this.answers.set(question.id, {
            formQuestionId: question.id,
          });
        });
      });

      fromAnswerSet?.answers?.forEach(answer => {
        if (answer?.value === '') {
          this.answer(answer?.formQuestionId, undefined, answer.comment);
        } else if (Number.isNaN(+answer?.value)) {
          this.answer(answer?.formQuestionId, answer?.value, answer.comment);
        } else {
          this.answer(answer?.formQuestionId, +answer.value, answer.comment);
        }
      });
    }
  }

  getAnswer(id: number) {
    return this.answers.get(id)?.value;
  }

  getComment(id: number) {
    return this.answers.get(id)?.comment;
  }

  answer(id: number, value: any, comment?: string) {
    const currentAnswer = this.answers.get(id);
    if (currentAnswer) {
      currentAnswer.value = value;
      currentAnswer.comment = comment;
    } else {
      throw new Error('Invalid id when registering answer');
    }
  }

  toAnswerSet(userId?: number, assignmentId?: string, studentUserId?: string, subjectId?: string | number, isMidSemester?: boolean): AnswerSet {
    return {
      formId: this.form.id,
      answers: Array.from(this.answers.values()),
      userId,
      assignmentId: assignmentId ? +assignmentId : undefined,
      studentUserId: studentUserId ? +studentUserId : undefined,
      subjectId: subjectId ? +subjectId : undefined,
      isMidSemester: isMidSemester !== undefined ? isMidSemester : undefined,
    };
  }
}

export default FormAnswers;
