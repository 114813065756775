import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import StudentWarningModalContent from 'src/components/StudentWarningModalContent/StudentWarningModalContent';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { Class } from 'src/models/classes';
import { User } from 'src/models/user';
import Button from '../../_common/Button/Button';
import LabeledTextArea from '../../_common/LabeledTextArea/LabeledTextArea';
import { useCreateStudentPassWarning } from './queries';

interface CreateModalProps {
  isOpen: boolean;
  close: () => void;
  class: Class;
  student: User;
  teacher?: User;
  supervisor?: User;
}

const COMPONENT_ID = 'send-student-warning-modal';

export default function CreateModal({ isOpen, close, class: _class, student, teacher, supervisor }: CreateModalProps) {
  const [comment, setComment] = useState('');
  const [passRequirement, setPassRequirement] = useState('');
  const [passRequirementSummary, setPassRequirementSummary] = useState('');

  const { formatMessage } = useIntl();
  const mutation = useCreateStudentPassWarning(onSuccess);

  useEffect(() => {
    function onParentClickHandler(e: MouseEvent) {
      const element = e.target as Element;
      const clickedOnParent = element?.children[0]?.id === COMPONENT_ID;
      if (clickedOnParent) {
        e.stopPropagation();
      }
    }

    document.getElementById(COMPONENT_ID)?.parentElement?.addEventListener('click', onParentClickHandler);

    return () => document.getElementById(COMPONENT_ID)?.parentElement?.removeEventListener('click', onParentClickHandler);
  }, [isOpen]);

  function onSuccess() {
    close();
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) return;

    mutation.mutate({
      classId: _class.id,
      studentUserId: student.id,
      comment,
      passRequirement,
      passRequirementSummary,
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      id={COMPONENT_ID}
      style={{ margin: 'auto' }}
    >
      <ModalHeader className="mb-4" data-testid="modal-header">
        <FormattedMessage id="student-about-to-fail-warning" />
      </ModalHeader>
      <form onSubmit={onSubmit} noValidate>
        <ModalBody>
          <StudentWarningModalContent
            student={student}
            class={_class}
            supervisor={supervisor}
            teacher={teacher}
            commentSlot={(
              <LabeledTextArea
                required
                defaultValue={comment}
                onValueChangeHandler={setComment}
                maxLength={2000}
                rows={5}
                labelText=""
                data-testid="comment"
              />
            )}
            passRequirementSlot={(
              <LabeledTextArea
                required
                defaultValue={passRequirement}
                onValueChangeHandler={e => setPassRequirement(e)}
                maxLength={2000}
                rows={5}
                labelText=""
                data-testid="pass-requirement"
              />
            )}
            passRequirementSummarySlot={(
              <LabeledTextArea
                required
                defaultValue={passRequirementSummary}
                onValueChangeHandler={e => setPassRequirementSummary(e)}
                maxLength={2000}
                rows={5}
                labelText=""
                data-testid="pass-requirement-summary"
              />
            )}
          />
          {mutation.isError && <ApiErrorAlert error={mutation.error} message={formatMessage({ id: 'sendStudentWarningModalCreateWarningErrorMessage' })} />}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!comment?.length || !passRequirement?.length || !passRequirementSummary?.length}
            text={formatMessage({ id: 'create' })}
            isSaving={mutation.isLoading}
            type="submit"
            data-testid="submit-button"
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
