export enum LabelType {
  MidSemesterExpectation,
  EndSemesterExpectation,
  Default
}

interface GradingOptionLabelProps {
  label: string;
  type: LabelType;
  showMidTermLabel?: boolean;
  inputElementId?: string;
}

export default function GradingOptionLabel({ label, type, showMidTermLabel, inputElementId }: GradingOptionLabelProps) {
  const renderLabel = () => {
    switch (type) {
      case LabelType.Default:
        return (
          <p className="text-xs xs:text-base text-darkgray">
            <label htmlFor={inputElementId}>{label}</label>
          </p>
        );
      case LabelType.MidSemesterExpectation:
        return (
          <p style={{ color: '#935900' }} className="text-xs xs:text-base text-secondary font-bold bg-secondary bg-opacity-40 px-2.5 py-0.5 rounded-full" data-testid="expected">
            <label htmlFor={inputElementId}>{label}</label>
          </p>
        );
      case LabelType.EndSemesterExpectation:
        return (
          <p className="text-xs xs:text-base text-darkgray border-b-2 border-secondary">
            <label htmlFor={inputElementId}>{label}</label>
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div
      key={`value-${label} `}
    >
      {showMidTermLabel && <div className="text-darkgray w-full">M</div>}
      {renderLabel()}
    </div>
  );
}
