import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ToastsContext from 'src/hooks/useToasts';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import useAcceptSupervisorInvitation from './queries';

export default function SupervisorInvitationAcceptPage() {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const { user } = LoggedInUserContext.useContainer();
  const verificationGuid = new URLSearchParams(search).get('verificationGuid') ?? '';
  const redirectUri = new URLSearchParams(search).get('redirect_uri') ?? null;

  const acceptInvitation = useAcceptSupervisorInvitation();
  const { showSuccessToastWithMessage } = ToastsContext.useContainer();
  const history = useHistory();

  useEffect(() => {
    acceptInvitation.mutate({ verificationGuid, email: user?.email });
  }, [verificationGuid]);

  useEffect(() => {
    if (acceptInvitation.isSuccess) {
      const message = formatMessage({ id: 'supervisorInvitationAcceptedToastMessage' });
      showSuccessToastWithMessage(message);
      if (redirectUri) history.push(redirectUri);
      else window.location.href = '/supervisor';
    }
  }, [acceptInvitation.isSuccess]);

  if (!user && !redirectUri) history.push(`/?verificationGuid=${verificationGuid}`);

  if (acceptInvitation.isError) {
    const message = formatMessage({ id: 'supervisorInvitationAcceptErrorMessage' });
    return <ApiErrorAlert error={acceptInvitation.error} message={message} />;
  }

  return <Spinner />;
}
