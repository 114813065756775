import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button } from '@windmill/react-ui';
import { useState, useEffect } from 'react';
import { HiPrinter } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import AnswersContext from 'src/hooks/useAnswers';
import { Form } from 'src/models/form';
import { useQuery } from '@tanstack/react-query';
import { fetchStudentPassWarning } from 'src/api/studentPassWarningAPI';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import ApiError from 'src/api/errorHandling/ApiError';
import StudentPassWarning from 'src/models/StudentPassWarning';
import useUser from 'src/hooks/user/useUser';
import SigningForm from 'src/models/SigningForm';
import { fetchSigningFormByClassAndStudent } from 'src/api/signingFormAPI';
import useFormExpectations from 'src/hooks/useFormExpectations';
import FormAnswers from '../../../../../components/FormComponent/FormAnswers';
import Spinner from '../../../../../components/_common/Spinner/Spinner';
import TableForPDF from './TableForPDF';

interface PageParams {
  formId: string,
  studentUserId: string,
  classId: string,
}

const TableForPDFPage = ({ form }: { form: Form }) => {
  const { classId, studentUserId, formId } = useParams<PageParams>();

  const { data: warning, isLoading, isError, error } = useQuery<StudentPassWarning | null, ApiError>(['studentWarning'], async () => {
    try {
      return await fetchStudentPassWarning({ classId: +classId, studentUserId: +studentUserId });
    } catch (e) {
      const apiError = e as ApiError;
      if (apiError.statusCode === 404) return null;
      throw apiError;
    }
  });

  const signingFormQuery = useQuery<SigningForm | null, ApiError>(['signingForm', classId, studentUserId], async () => {
    try {
      return await fetchSigningFormByClassAndStudent(+classId, +studentUserId);
    } catch (e) {
      const apiError = e as ApiError;
      if (apiError.statusCode === 404) return null;
      throw apiError;
    }
  });
  const { data: student, isLoading: studentIsLoading, isError: studentIsError, error: studentError } = useUser(+studentUserId);

  const [anyAnswerLoading, setAnyAnswerLoading] = useState<boolean>(true);
  const { formatMessage } = useIntl();

  const [teacherMidTermAnswers, setTeacherMidTermAnswers] = useState<FormAnswers>();
  const [teacherEndTermAnswers, setTeacherEndTermAnswers] = useState<FormAnswers>();

  const [studentComment, setStudentComment] = useState<string | undefined>();
  const [supervisorComment, setSupervisorComment] = useState<string | undefined>();
  const [teacherComment, setTeacherComment] = useState<string | undefined>();

  const midSemesterExpectationsQuery = useFormExpectations(+classId, +formId, true);
  const endSemesterExpectationsQuery = useFormExpectations(+classId, +formId, false);

  const {
    getGradedAnswerSetIds,
    gradedAnswerSetIdsLoaded,
    gradedAnswerSetIds,
    getAnswers,
  } = AnswersContext.useContainer();

  useEffect(() => {
    if (classId && studentUserId && form) {
      if (!gradedAnswerSetIdsLoaded) {
        getGradedAnswerSetIds(+classId, +studentUserId, form.id);
      }
    }
  }, [form, gradedAnswerSetIdsLoaded]);

  const handleGetAnswerSet = async (
    promises: Array<Promise<any> |undefined>,
    setAnswersFunction?: (value: any) => void,
    answerSetId?: number,
    setCommentFunction?: (value: any) => void,
  ) => {
    if (answerSetId && form) {
      promises.push(getAnswers(answerSetId).then(res => {
        if (setAnswersFunction)setAnswersFunction(new FormAnswers(form, res.data));
        if (setCommentFunction) {
          const comments = res.data.answers.filter((answer: any) => Number.isNaN(+answer.value)).map((answer: any) => answer.value);
          setCommentFunction(comments.at(-1));
        }
      }).catch(e => e));
    }
  };

  const handleGetAnswers = async () => {
    const promises: Array<Promise<any> |undefined> = [];

    if (form && form.isMainForm) {
      handleGetAnswerSet(promises, setTeacherMidTermAnswers, gradedAnswerSetIds?.midSemester?.teacher?.id);
    }
    handleGetAnswerSet(promises, setTeacherEndTermAnswers, gradedAnswerSetIds?.endSemester?.teacher?.id, setTeacherComment);
    handleGetAnswerSet(promises, undefined, gradedAnswerSetIds?.endSemester?.advisor?.id, setSupervisorComment);
    handleGetAnswerSet(promises, undefined, gradedAnswerSetIds?.endSemester?.student?.id, setStudentComment);

    Promise.allSettled(promises).then(() => setAnyAnswerLoading(false));
  };

  useEffect(() => {
    if (gradedAnswerSetIdsLoaded) {
      handleGetAnswers();
    }
  }, [gradedAnswerSetIdsLoaded]);

  const formatMessageWrapper = (id: string) => formatMessage({ id });

  if (!form
    || anyAnswerLoading
    || isLoading
    || warning === undefined
    || studentIsLoading
    || student === undefined
    || signingFormQuery.isLoading
    || signingFormQuery.data === undefined
    || midSemesterExpectationsQuery.isLoading
    || endSemesterExpectationsQuery.isLoading) return <Spinner />;

  if (isError) return <ApiErrorAlert error={error!} message="En feil oppsto ved uthenting av varsel om mulig ikke bestått for student." />;
  if (studentIsError) return <ApiErrorAlert error={studentError!} message="En feil oppsto ved uthenting av student." />;
  if (signingFormQuery.isError) return <ApiErrorAlert error={signingFormQuery.error} message="En feil oppsto ved uthenting av signeringsskjema." />;
  if (midSemesterExpectationsQuery.isError) return <ApiErrorAlert error={midSemesterExpectationsQuery.error} message="En feil oppsto ved uthenting av forventinger for midtvurdering." />;
  if (endSemesterExpectationsQuery.isError) return <ApiErrorAlert error={endSemesterExpectationsQuery.error} message="En feil oppsto ved uthenting av forventinger for sluttvurdering." />;

  const studentName = student.displayName;
  const midSemesterFormAnswers = midSemesterExpectationsQuery.data === null ? undefined : new FormAnswers(form, midSemesterExpectationsQuery.data);
  const endSemesterFormAnswers = endSemesterExpectationsQuery.data === null ? undefined : new FormAnswers(form, endSemesterExpectationsQuery.data);

  return (
    <>
      <PDFDownloadLink
        document={(
          <TableForPDF
            studentName={studentName}
            form={form}
            teacherEndTermAnswers={teacherEndTermAnswers}
            teacherMidTermAnswers={teacherMidTermAnswers}
            midTermExpectations={midSemesterFormAnswers}
            endTermExpectations={endSemesterFormAnswers}
            studentComment={studentComment}
            supervisorComment={supervisorComment}
            teacherComment={teacherComment}
            studentWarning={warning}
            signingForm={signingFormQuery.data}
            formatMessageWrapper={formatMessageWrapper}
          />
          )}
        fileName={`${studentName}-${form.name}`}
      >
        {({ loading }) => (
          <div className="w-full py-4">
            <Button className="self-center w-auto bg-primary" iconLeft={HiPrinter}>
              {loading ? '...' : <FormattedMessage id="save-pdf" />}
            </Button>
          </div>
        )}
      </PDFDownloadLink>
      <PDFViewer style={{ width: '100%', height: '50rem' }}>
        <TableForPDF
          studentName={studentName}
          form={form}
          teacherEndTermAnswers={teacherEndTermAnswers}
          teacherMidTermAnswers={teacherMidTermAnswers}
          midTermExpectations={midSemesterFormAnswers}
          endTermExpectations={endSemesterFormAnswers}
          studentComment={studentComment}
          supervisorComment={supervisorComment}
          teacherComment={teacherComment}
          studentWarning={warning}
          signingForm={signingFormQuery.data}
          formatMessageWrapper={formatMessageWrapper}
        />
      </PDFViewer>
    </>
  );
};

export default TableForPDFPage;
