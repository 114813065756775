import { ReactNode } from 'react';
import { Badge } from '@windmill/react-ui';

export enum CommentType {
  Supervisor,
  Teacher,
  Student
}

interface CommentProps{
  type: CommentType,
  children: ReactNode | ReactNode[],
}

export default function Comment({ type, children }: CommentProps) {
  function getBadgeLabel() {
    switch (type) {
      case CommentType.Student:
        return 'S';
      case CommentType.Supervisor:
        return 'PV';
      case CommentType.Teacher:
        return 'PL';
      default:
        throw new Error(`CommentType ${type} is not valid.`);
    }
  }

  function getBadgeClassName() {
    switch (type) {
      case CommentType.Student:
        return 'bg-student text-studentText text-xs font-bold mr-2 h-full';
      case CommentType.Supervisor:
        return 'bg-supervisor text-supervisorText text-xs font-bold mr-2 h-full';
      case CommentType.Teacher:
        return 'text-teacherText text-xs font-bold mr-2 bg-teacher h-full';
      default:
        throw new Error(`CommentType ${type} is not valid.`);
    }
  }

  return (
    <div className="border-t py-4 border-lightGray items-center">
      <Badge className={getBadgeClassName()}>
        {getBadgeLabel()}
      </Badge>
      <span>{children}</span>
    </div>
  );
}
