import { FC } from 'react';
import { FormQuestionGroup } from 'src/models/form';
import FormAnswers from '../FormAnswers';
import FormQuestionComponent from '../FormQuestionComponent/FormQuestionComponent';

export interface FormQuestionGroupProps {
  questionGroup: FormQuestionGroup,
  answers: FormAnswers | undefined,
  midTermExpectations: FormAnswers | undefined,
  endTermExpectations: FormAnswers | undefined,
  studentGrades: FormAnswers | undefined,
  supervisorGrades: FormAnswers | undefined,
  teacherGrades: FormAnswers | undefined,
  teacherMidTermGrades: FormAnswers | undefined,
  studentMidTermGrades: FormAnswers | undefined,
  supervisorMidTermGrades: FormAnswers | undefined,
  isMidSemester: boolean,
  questionSchemeOrder: (groupOrder: number, questionOrder: number) => number
}

export const FormQuestionGroupComponent: FC<FormQuestionGroupProps> = ({
  questionGroup,
  answers,
  midTermExpectations,
  endTermExpectations,
  studentGrades,
  supervisorGrades,
  teacherGrades,
  teacherMidTermGrades,
  studentMidTermGrades,
  supervisorMidTermGrades,
  isMidSemester,
  questionSchemeOrder,
}: FormQuestionGroupProps) => {
  const sortedQuestions = questionGroup.questions?.sort((a, b) => a.order - b.order);

  return (
    <>
      <h2 className="group">{questionGroup?.name}</h2>
      {sortedQuestions && sortedQuestions.map(question => (
        <FormQuestionComponent
          question={question}
          options={questionGroup.options}
          type={questionGroup.type}
          key={question.id ?? Math.random()}
          answers={answers}
          teacherGrades={teacherGrades}
          midTermExpectations={midTermExpectations}
          endTermExpectations={endTermExpectations}
          studentGrades={studentGrades}
          teacherMidTermGrades={teacherMidTermGrades}
          supervisorGrades={supervisorGrades}
          isMidSemester={isMidSemester}
          questionSchemeOrder={questionSchemeOrder}
          groupOrder={questionGroup.order}
          studentMidTermGrades={studentMidTermGrades}
          supervisorMidTermGrades={supervisorMidTermGrades}
        />
      ))}
    </>
  );
};

export default FormQuestionGroupComponent;
