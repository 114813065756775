import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'src/models/form';
import { useFormProvider } from 'src/context/FormProviderContext';
import FormComponent from 'src/components/FormComponent/FormComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { HiExternalLink, HiPrinter } from 'react-icons/hi';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Button from 'src/components/_common/Button/Button';
import ClassesContext from 'src/hooks/useClasses';
import TableForPDFPage from './TableForPDF/TableForPDFPage';

export const FormPage = ({ canDeliver = true, canPrint = false }: {canDeliver?: boolean, canPrint?: boolean}) => {
  const formProvider = useFormProvider();
  const [form, setForm] = useState<Form | undefined>(undefined);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [showPrint, setShowPrinting] = useState<boolean>(false);
  const history = useHistory();
  const { location } = history;
  const { classId, formId, assignmentId } = useParams<{ classId: string, formId: string, assignmentId: string }>();
  const intl = useIntl();
  const { currentClass, getCurrentClass } = ClassesContext.useContainer();
  const [title, setTitle] = useState('');

  useEffect(() => {
    formProvider.getForm(Number.parseInt(formId, 10)).then(receivedForm => {
      const sortForm = (obj: Form) => {
        const temp = { ...obj, groups: obj?.groups.sort((a, b) => ((a.order > b.order) ? 1 : -1)) };
        return temp;
      };
      if (receivedForm) setForm(sortForm(receivedForm));
      setFormLoaded(true);
    });
  }, [formId]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearState);
    return () => {
      window.removeEventListener('beforeunload', clearState);
    };
  }, []);

  const clearState = () => {
    window.history.replaceState({}, '');
  };

  useEffect(() => {
    if (classId && currentClass?.id !== +classId) getCurrentClass(+classId);
  }, [classId]);

  useEffect(() => {
    if (currentClass && form) {
      const isMidSemester = currentClass.assignments?.find(assignment => assignment.classId === +classId && assignment.id === +assignmentId)?.isMidSemester;
      setTitle(`${isMidSemester ? intl.formatMessage({ id: 'mid-semester' }) : intl.formatMessage({ id: 'end-semester' })} - ${form.name}`);
    } else if (form) {
      setTitle(form.name);
    }
  }, [currentClass, form]);

  const ifHasPath: string = (location.state as any)?.pPath;
  if (!ifHasPath) {
    history.replace('/');
  }

  const handlePrint = () => {
    setShowPrinting(true);
  };

  function openIndependenceScale() {
    window.open('/independence-scale', '_blank');
  }

  if (!formLoaded && ifHasPath) return <Spinner />;
  if (showPrint && form && ifHasPath) return <TableForPDFPage form={form} />;
  if (!ifHasPath) return <></>;

  return (
    <>
      <PageHeader title={title}>
        {canPrint && (
          <Button onClick={handlePrint} iconLeft={HiPrinter} className="mt-4 mr-4">
            <FormattedMessage id="show-pdf" />
          </Button>
        )}
        <Button onClick={openIndependenceScale} iconRight={HiExternalLink} layout="outline">
          <FormattedMessage id="independenceScale" />
        </Button>
      </PageHeader>
      {form && <FormComponent form={form} canDeliver={canDeliver} /> }
      {!form && <h2 data-testid="form-not-found"><FormattedMessage id="form-not-found" /></h2>}
    </>
  );
};

export default FormPage;
