import StudentPassWarning from 'src/models/StudentPassWarning';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

interface FetchStudentPassWarningParameters {
  classId: number;
  studentUserId: number;
}

export async function fetchStudentPassWarning({ classId, studentUserId }: FetchStudentPassWarningParameters) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/warning`);
    const warning = res.data.data as StudentPassWarning;

    warning.createdAt = new Date(warning.createdAt);
    if (warning.studentConfirmedWarningAt) {
      warning.studentConfirmedWarningAt = new Date(warning.studentConfirmedWarningAt);
    }
    return warning;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export interface CreateStudentPassWarningParameters {
  classId: number;
  studentUserId: number;
  comment: string;
  passRequirement: string;
  passRequirementSummary: string;
}

export async function createStudentPassWarning(parameters: CreateStudentPassWarningParameters) {
  try {
    const { classId, studentUserId } = parameters;
    const data = { data: { ...parameters } };
    await api.post(`api/classes/${classId}/students/${studentUserId}/warning`, data);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

interface DeleteStudentPassWarningParameters {
  classId: number;
  studentUserId: number;
}

export async function deleteStudentPassWarning({ classId, studentUserId }: DeleteStudentPassWarningParameters) {
  try {
    await api.delete(`api/classes/${classId}/students/${studentUserId}/warning`);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

interface ConfirmStudentPassWarningParameters {
  classId: number;
  studentUserId: number;
}

export async function confirmStudentPassWarning({ classId, studentUserId }: ConfirmStudentPassWarningParameters) {
  try {
    await api.post(`api/classes/${classId}/students/${studentUserId}/warning/confirm`);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
