import React from 'react';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { HiPlus } from 'react-icons/hi';
import Button from 'src/components/_common/Button/Button';
import { useHistory } from 'react-router-dom';
import Card from 'src/components/_common/Card/Card';
import { useIntl } from 'react-intl';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import UserBadge from 'src/components/_common/Badge/UserBadge';
import SupervisorBadge from './SupervisorBadge';
import { useFormListPageData } from './queries';
import AssignmentFormsLists from './AssignmentFormsLists';
import InvitationStatusLabel from './InvitationStatusLabel';

const StudentForms: React.FC = () => {
  const query = useFormListPageData();
  const history = useHistory();
  const { formatMessage } = useIntl();

  function navigateToEditSupervisor() {
    history.push('/student/supervisor/invite');
  }

  if (query.isLoading) return <Spinner />;
  if (query.isError) {
    const message = formatMessage({ id: 'formListPageErrorMessage' });
    return <ApiErrorAlert error={query.error} message={message} />;
  }

  const { subject } = query.data.class;
  const teacherUserId = query.data.user.studentReferences?.teacherUserId;
  const supervisorUserId = query.data.user.studentReferences?.advisorUserId;

  return (
    <>
      <PageHeader title={subject?.subjectCode} subTitle={subject?.name} />

      <div className="flex w-full justify-center">
        <div>
          {teacherUserId && <UserBadge id={teacherUserId} />}
        </div>
        <div>
          {supervisorUserId ? (
            <div>
              <SupervisorBadge id={supervisorUserId} onEditClick={navigateToEditSupervisor} />
            </div>
          ) : (
            <Button onClick={navigateToEditSupervisor} size="small" className="ml-2 mt-2 relative" style={{ bottom: 2 }} data-testid="invite-supervisor-button">
              <HiPlus className="mr-1" />
              {formatMessage({ id: 'formListPageInviteSupervisorButtonText' })}
            </Button>
          )}
          <InvitationStatusLabel />
        </div>
      </div>
      {query.data.signingForm
        && (
          <div data-testid="signing-form-card-container">
            <Card
              className="mt-8"
              primary={false}
              onClick={() => history.push(`student/class/${query.data.class.id}/student/${query.data.user.id}/signingform`)}
              header={formatMessage({ id: 'signing-form-header' })}
            />
          </div>
        )}
      <AssignmentFormsLists classData={query.data.class} signingForm={query.data.signingForm} supervisorExists={!!supervisorUserId} />
    </>
  );
};

export default StudentForms;
