import { Switch, Route, Redirect } from 'react-router-dom';
import StudentPage from 'src/components/StudentPage/StudentPage';
import { RoleTypes } from 'src/models/roles';
import SigningFormPage from 'src/components/SigningForm/SigningFormPage';
import FormPage from '../Student/StudentForms/FormPage/FormPage';
import SupervisorPage from './TeacherClass/SupervisorPage/SupervisorPage';
import TeacherClass from './TeacherClass/TeacherClass';
import TeacherClasses from './TeacherClasses/TeacherClasses';
import PrivacyPolicyPage from '../Main/PrivacyPolicy/PrivacyPolicyPage';

const TeacherRouter = () => (
  <Switch>
    <Route exact path="/teacher">
      <TeacherClasses />
    </Route>
    <Route exact path="/teacher/class/:classId">
      <TeacherClass />
    </Route>
    <Route exact path="/teacher/form/:formId">
      <FormPage />
    </Route>
    <Route exact path="/teacher/class/:classId/student/:userId">
      <StudentPage activeRole={RoleTypes.Teacher} />
    </Route>
    <Route exact path="/teacher/class/:classId/advisor/:userId">
      <SupervisorPage />
    </Route>
    <Route path="/teacher/class/:classId/student/:studentUserId/form/:formId/assignment/:assignmentId">
      <FormPage canPrint />
    </Route>
    <Route path="/teacher/class/:classId/student/:studentUserId/signingform">
      <SigningFormPage />
    </Route>
    <Route path="/privacy-policy">
      <PrivacyPolicyPage />
    </Route>
    <Redirect from="*" to="/teacher" />
  </Switch>
);
export default TeacherRouter;
