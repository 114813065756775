import { useState } from 'react';
import { useHttpClient, HttpResponse } from 'src/context/HttpClientContext';
import { User, UserReferences } from 'src/models/user';
import { createContainer } from 'unstated-next';
import ToastsContext from './useToasts';

export interface Warning {
  createdAt: string
  id: number
  studentReferencesId: number
  studentConfirmedWarningAt: string
  passRequirement: string
  comment: string
}

function useUsers() {
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const [users, setUsers] = useState<Array<User> | undefined>();
  const [user, setUser] = useState<User| undefined>();
  const [teachers, setTeachers] = useState<Array<User>| undefined>();
  const [supervisors, setSupervisors] = useState<Array<User>| undefined>();
  const [students, setStudents] = useState<Array<User>| undefined>();

  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [usersWithRoleLoaded, setUsersWithRoleLoaded] = useState<boolean>(false);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [loadingMoreUsers, toggleLoadingMoreUsers] = useState<boolean>(false);
  const [usersError, setUsersError] = useState<HttpResponse<any> | undefined>();

  const [studentWarning, setStudentWarning] = useState<Warning>();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { get, patch, post } = useHttpClient();

  const handleSetUsers = (_users: Array<User>) => {
    setUsers(_users);
    setUsersLoaded(true);
  };
  const handleSetUser = (_user: User) => {
    setUser(_user);
    setUserLoaded(true);
  };

  const getUsers = (roleId?: number, search?:string, pagesize?: number, offset?: number, add?: boolean, includeInactive?: boolean) => {
    const searchParams = new URLSearchParams();
    if (add) toggleLoadingMoreUsers(true);
    if (roleId) searchParams.append('roleid', roleId.toString());
    if (search) searchParams.append('search', search);
    if (pagesize) searchParams.append('pagesize', pagesize.toString());
    if (add && (offset && offset >= 20)) searchParams.append('offset', offset?.toString());
    searchParams.append('includeInactive', includeInactive ? '1' : '0');
    get({ url: `api/users?${searchParams}` })
      .then(async response => {
        if (add) {
          setUsers(users?.concat(response.data as Array<User>));
          toggleLoadingMoreUsers(false);
        } else {
          handleSetUsers(response.data as Array<User>);
        }
      }).catch(error => {
        setUsersError(error);
      }).finally(() => setUsersLoaded(true));
  };

  const getStudents = () => {
    setUsersWithRoleLoaded(false);
    get({ url: 'api/users?roleid=2&pagesize=20' })
      .then(response => {
        setStudents(response.data as Array<User>);
      })
      .catch(error => {
        setUsersError(error);
      }).finally(() => setUsersWithRoleLoaded(true));
  };

  const getTeachers = () => {
    setUsersWithRoleLoaded(false);
    get({ url: 'api/users?roleid=3&pagesize=20' })
      .then(response => {
        setTeachers(response.data as Array<User>);
      })
      .catch(error => {
        setUsersError(error);
      }).finally(() => setUsersWithRoleLoaded(true));
  };

  const getSupervisors = () => {
    setUsersWithRoleLoaded(false);
    get({ url: 'api/users?roleid=4&pagesize=20' })
      .then(response => {
        setSupervisors(response.data as Array<User>);
      })
      .catch(error => {
        setUsersError(error);
      })
      .finally(() => setUsersWithRoleLoaded(true));
  };

  const updateUser = (userId: number, userReferences: UserReferences, isActive?: boolean, onSuccess?: () => void) => {
    setIsSaving(true);
    let data: any = {
      ...userReferences,
    };
    if (isActive !== undefined) data = { ...data, isActive };

    patch({ url: `api/users/${userId}`, data })
      .then(() => setShowSuccessToast(true))
      .catch(error => {
        setErrorToast(error);
      }).finally(() => {
        setIsSaving(false);
        onSuccess?.();
      });
  };

  const getUser = (userId: number) => get({ url: `api/users/${userId}` })
    .then(response => handleSetUser(response.data as User))
    .catch(error => {
      setUsersError(error);
    }).finally(() => setUserLoaded(true));

  const getStudentWarning = (classId: number, studentUserId: number) => get({ url: `api/classes/${classId}/students/${studentUserId}/warning` })
    .then(response => setStudentWarning(response.data as Warning)).catch(() => setStudentWarning(undefined));

  const acceptStudentWarning = (classId: number, studentUserId: number, closeModal: () => void) => post({ url: `api/classes/${classId}/students/${studentUserId}/warning/confirm` })
    .then(() => {
      setShowSuccessToast(true);
      closeModal();
      getStudentWarning(classId, studentUserId);
    }).catch(err => setErrorToast(err));

  const [sendingWarning, setSendingWarning] = useState(false);
  const sendWarning = (classId: number, studentId: number, comment: string, passRequirement:string, closeModal: () => void) => {
    setSendingWarning(true);
    return post({ url: `api/classes/${classId}/students/${studentId}/warning`, data: { comment, passRequirement } }).then((() => {
      setShowSuccessToast(true);
      closeModal();
      window.location.reload();
    })).catch(err => setErrorToast(err)).finally(() => setSendingWarning(false));
  };
  return {
    users,
    handleSetUsers,
    handleSetUser,
    usersLoaded,
    userLoaded,
    usersError,
    setUsersError,
    getUsers,
    loadingMoreUsers,
    updateUser,
    getUser,
    getTeachers,
    getSupervisors,
    user,
    teachers,
    supervisors,
    getStudents,
    students,
    isSaving,
    usersWithRoleLoaded,
    getStudentWarning,
    studentWarning,
    sendingWarning,
    sendWarning,
    acceptStudentWarning,
    setStudentWarning,
  };
}

const UsersContext = createContainer(useUsers);

export default UsersContext;
