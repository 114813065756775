import FormAnswers from 'src/components/FormComponent/FormAnswers';
import { Form, QuestionType } from 'src/models/form';
import SigningForm from 'src/models/SigningForm';
import StudentPassWarning from 'src/models/StudentPassWarning';
import CommentText from './CommentText';
import FormQuestionGroupDisplay from './FormQuestionGroupDisplay';
import WarningDisplay from './WarningDisplay';

interface TableBodyProps {
  teacherEndTermAnswers: FormAnswers | undefined,
  teacherMidTermAnswers: FormAnswers | undefined,
  midTermExpectations: FormAnswers | undefined,
  endTermExpectations: FormAnswers | undefined,
  studentComment: string | undefined,
  supervisorComment: string | undefined,
  teacherComment: string | undefined,
  form: Form
  studentWarning: StudentPassWarning | null,
  studentName: string | undefined,
  signingForm: SigningForm | null,
  formatMessageWrapper: (id: string) => string
}

const TableBody = ({
  teacherEndTermAnswers,
  teacherMidTermAnswers,
  form,
  midTermExpectations,
  endTermExpectations,
  studentComment,
  supervisorComment,
  teacherComment,
  studentWarning,
  studentName,
  signingForm,
  formatMessageWrapper,
}: TableBodyProps) => {
  function getSigningFormText() {
    if (!signingForm) return '';
    const gradingText = signingForm.overallPassed ? formatMessageWrapper('signing-form-passed') : formatMessageWrapper('signing-form-failed');
    return `${formatMessageWrapper('practicePeriodGradedTo')} ${gradingText.toLowerCase()}`;
  }
  return (
    <>
      {form.groups.filter(g => g.type === QuestionType.Grading).map(group => (
        <FormQuestionGroupDisplay
          teacherEndTermAnswers={teacherEndTermAnswers}
          teacherMidTermAnswers={teacherMidTermAnswers}
          midTermExpectations={midTermExpectations}
          endTermExpectations={endTermExpectations}
          questionGroup={group}
          isMainForm={form.isMainForm}
        />
      ))}
      {studentComment && <CommentText label={formatMessageWrapper('student')} text={studentComment} />}
      {supervisorComment && <CommentText label={formatMessageWrapper('supervisor')} text={supervisorComment} />}
      {teacherComment && <CommentText label={formatMessageWrapper('teacher')} text={teacherComment} />}
      <WarningDisplay warning={studentWarning} studentName={studentName} formatMessageWrapper={formatMessageWrapper} />
      {signingForm && <CommentText text={getSigningFormText()} />}
    </>
  );
};
export default TableBody;
