import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchMyMatchingFeideClasses, joinClass } from 'src/api/classAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import { Class } from 'src/models/classes';

const queryKey = ['matchingFeideClasses'];

export function useMatchingFeideClassesQuery() {
  return useQuery<Class[], ApiError>({
    queryKey,
    queryFn: fetchMyMatchingFeideClasses,
  });
}

export function useJoinClass(onSuccess: () => void) {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, number>({
    mutationFn: joinClass,
    onError: () => {
      // Trigger fetch of classes again to make sure we have the latest data
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess,
  });
}
