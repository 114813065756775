import { useQuery } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchFormExpectations } from 'src/api/expectationsAPI';
import { AnswerSet } from 'src/components/FormComponent/FormAnswers';

export default function useFormExpectations(classId: number, formId: number, isMidSemester: boolean) {
  return useQuery<AnswerSet | null, ApiError>(['useFormExpectations', classId, formId, isMidSemester], async () => {
    try {
      return await fetchFormExpectations(classId, formId, isMidSemester);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.statusCode === 404) return null;
      throw apiError;
    }
  });
}
