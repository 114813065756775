import { useQuery } from '@tanstack/react-query';
import { fetchFeideGroupMemberships, fetchFeideUserInfo } from 'src/api/authAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import FeideGroupMembership from 'src/models/FeideGroupMembership';
import FeideUserInfo from 'src/models/FeideUserInfo';

interface FsDebugQuery {
  userInfo: FeideUserInfo;
  memberships: FeideGroupMembership[];
}

export default function useFsDebugQuery() {
  return useQuery<FsDebugQuery, ApiError>({
    queryKey: ['fsDebug'],
    queryFn: async () => {
      const [userInfo, memberships] = await Promise.all([
        fetchFeideUserInfo(),
        fetchFeideGroupMemberships(),
      ]);
      return { userInfo, memberships };
    },
  });
}
