/* eslint-disable react/no-array-index-key */
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import FormAnswers from 'src/components/FormComponent/FormAnswers';
import { FormQuestion, FormQuestionGroup } from 'src/models/form';

interface FormQuestionGroupDisplayProps {
  questionGroup: FormQuestionGroup;
  teacherEndTermAnswers: FormAnswers | undefined,
  teacherMidTermAnswers: FormAnswers | undefined,
  midTermExpectations: FormAnswers | undefined,
  endTermExpectations: FormAnswers | undefined,
  isMainForm: boolean;
}

const styles = StyleSheet.create({
  questionRows: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'stretch',
  },
  questionContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
  },
  semesterWrapper: {
    flexDirection: 'column',
    borderRight: '1px solid #c2d9ff',
    borderBottom: '1px solid #c2d9ff',
    flex: 1,
  },
  description: {
    borderRight: '1px solid #c2d9ff',
    borderBottom: '1px solid #c2d9ff',
    fontWeight: 'light',
    justifyContent: 'center',
    padding: '4px',
    flex: 9,
  },
  answerRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #c2d9ff',
    flexGrow: 1,
  },
  groupName: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2478ff',
    padding: '4px 0px',
  },
  groupContainer: {
    flexDirection: 'column',
    width: '100%',
    fontSize: 12,
  },
  expected: {
    backgroundColor: '#ffc875',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #c2d9ff',
  },
  answersWrapper: {
    flexDirection: 'column',
    flex: 12,
  },
  answer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #c2d9ff',
  },
  semesterText: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  borderBottom: {
    borderBottom: '1px solid #c2d9ff',
  },
});

export default function FormQuestionGroupDisplay({ questionGroup, teacherEndTermAnswers, teacherMidTermAnswers, midTermExpectations, endTermExpectations, isMainForm }: FormQuestionGroupDisplayProps) {
  return (
    <View key={questionGroup.id} style={styles.groupContainer} wrap={false}>
      <Text style={styles.groupName}>{questionGroup.name}</Text>
      <View style={styles.questionRows}>
        {questionGroup.questions.map((question, i) => {
          const teacherEndTermAnswer = +teacherEndTermAnswers?.getAnswer(question?.id);
          const teacherMidTermAnswer = +teacherMidTermAnswers?.getAnswer(question?.id);

          const midTermExpectation = +midTermExpectations?.getAnswer(question?.id);
          const endTermExpectation = +endTermExpectations?.getAnswer(question?.id);
          return (
            <View key={question.id + i}>
              {!isMainForm
                ? (
                  <NotMainFormRow
                    question={question}
                    endTermAnswer={teacherEndTermAnswer}
                    endTermExpectation={endTermExpectation}
                  />
                ) : (
                  <MainFormRow
                    question={question}
                    midTermAnswer={teacherMidTermAnswer}
                    endTermAnswer={teacherEndTermAnswer}
                    midTermExpectation={midTermExpectation}
                    endTermExpectation={endTermExpectation}
                  />
                )}
            </View>
          );
        })}
      </View>
    </View>
  );
}

interface NotMainFormRowprops {
  question: FormQuestion,
  endTermAnswer?: number,
  endTermExpectation?: number
}

interface MainFormRowProps extends NotMainFormRowprops {
  midTermAnswer?: number
  midTermExpectation?: number
}

const defaultLabels = ['x', ' ', ' ', ' ', ' ', ' '];

const MainFormRow = ({ question, endTermAnswer, endTermExpectation, midTermAnswer, midTermExpectation }: MainFormRowProps) => (
  <View style={styles.questionContainer} wrap={false}>
    <View style={styles.description}>
      <Text>{question.text}</Text>
    </View>
    <View style={styles.semesterWrapper}>
      <View style={[styles.semesterText, styles.borderBottom]}><Text>M</Text></View>
      <View style={styles.semesterText}><Text>S</Text></View>
    </View>
    <View style={styles.answersWrapper}>
      <View style={styles.answerRow}>
        {defaultLabels.map((_, index) => <View key={index} style={midTermExpectation === index ? styles.expected : styles.answer}><Text>{index === midTermAnswer ? 'X' : ' '}</Text></View>)}
      </View>
      <View style={styles.answerRow}>
        {defaultLabels.map((_, index) => <View key={index} style={endTermExpectation === index ? styles.expected : styles.answer}><Text>{index === endTermAnswer ? 'X' : ' '}</Text></View>)}
      </View>
    </View>
  </View>
);

const NotMainFormRow = ({ question, endTermAnswer, endTermExpectation }: NotMainFormRowprops) => (
  <View style={styles.questionContainer} wrap={false}>
    <View style={styles.description}>
      <Text>{question.text}</Text>
    </View>
    <View style={styles.semesterWrapper} />
    <View style={styles.answersWrapper}>
      <View style={styles.answerRow}>
        {defaultLabels.map((_, index) => <View key={index} style={endTermExpectation === index ? styles.expected : styles.answer}><Text>{index === endTermAnswer ? 'X' : ' '}</Text></View>)}
      </View>
    </View>
  </View>
);
