import { AnswerSet } from 'src/components/FormComponent/FormAnswers';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

// eslint-disable-next-line import/prefer-default-export
export async function fetchFormExpectations(classId: number, formId: number, isMidsemester: boolean) {
  try {
    const result = await api.get(`api/classes/${classId}/forms/${formId}/expectations?isMidSemester=${isMidsemester}`);
    return result.data.data as AnswerSet;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
