import { Badge } from '@windmill/react-ui';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeledInput from 'src/components/_common/LabeledInput/LabeledInput';
import Comment, { CommentType } from './Comment';

interface FormQuestionDisagreementsProps {
  showNewDisagreement?: boolean,
  disagreementComment?: string,
  changeDisagreementComment: (comment: string) => void,
  teacherComment?: string,
  supervisorComment?: string,
  studentComment?: string,
  teacherMidTermComment?: string,
  supervisorMidTermComment?: string,
  studentMidTermComment?: string,
  activeColor?: string,
  disagreementBadgeText: string,
  noTypePicker?: boolean,
  isMidSemester: boolean,
}

const FormQuestionDisagreements = ({
  showNewDisagreement,
  teacherComment,
  supervisorComment,
  studentComment,
  teacherMidTermComment,
  supervisorMidTermComment,
  studentMidTermComment,
  activeColor,
  disagreementComment,
  changeDisagreementComment,
  disagreementBadgeText,
  noTypePicker,
  isMidSemester,
}: FormQuestionDisagreementsProps) => {
  const { formatMessage } = useIntl();

  function showPreviousCommentsForMidTermAnswers() {
    if (isMidSemester) return false;
    return teacherMidTermComment || supervisorMidTermComment || studentMidTermComment;
  }

  return (
    <>
      {((showNewDisagreement || noTypePicker) || teacherComment || supervisorComment || studentComment)
        && (
          <div className={!noTypePicker ? 'mt-8' : ''}>
            {(showNewDisagreement || noTypePicker)
              && (
                <div className="flex py-4 border-lightGray items-center">
                  <Badge className={classNames(
                    `bg-${activeColor}`,
                    `text-${activeColor}Text`,
                    'text-xs font-bold mr-2 h-full',
                  )}
                  >
                    {disagreementBadgeText}
                  </Badge>
                  <LabeledInput
                    onValueChangeHandler={changeDisagreementComment}
                    value={disagreementComment || ''}
                    className="w-full"
                    labelText={formatMessage({ id: 'comment' })}
                  />
                </div>
              )}
          </div>
        )}
      <div>
        {teacherComment && (
          <Comment type={CommentType.Teacher}>{teacherComment}</Comment>
        )}
        {supervisorComment && (
          <Comment type={CommentType.Supervisor}>{supervisorComment}</Comment>
        )}
        {studentComment && (
          <Comment type={CommentType.Student}>{studentComment}</Comment>
        )}
        {showPreviousCommentsForMidTermAnswers() && (
          <>
            <h5>
              <FormattedMessage id="commentsFromMidTerm" />
            </h5>
            {teacherMidTermComment && (
            <Comment type={CommentType.Teacher}>{teacherMidTermComment}</Comment>
            )}
            {supervisorMidTermComment && (
            <Comment type={CommentType.Supervisor}>{supervisorMidTermComment}</Comment>
            )}
            {studentMidTermComment && (
            <Comment type={CommentType.Student}>{studentMidTermComment}</Comment>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FormQuestionDisagreements;
