import { Input } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import DateOnly from 'src/models/common/DateOnly';
import SigningForm from 'src/models/SigningForm';
import DateOnlyInput from '../_common/DateOnlyInput/DateOnlyInput';
import RadioButtonRow from './RadioButtonRow';

interface FormFieldsProps {
  form: SigningForm;
  setForm: (form: SigningForm) => void;
  disabled: boolean;
}

export default function EndSemesterFormFields({ form, setForm, disabled }: FormFieldsProps) {
  const { formatMessage } = useIntl();

  function handleChange(fieldName: string, value: boolean | Date) {
    setForm({ ...form, [fieldName]: value });
  }

  return (
    <>
      <h3 className="border-b-2" style={{ borderColor: 'black' }}>
        <FormattedMessage id="end-semester" />
      </h3>
      <div className="flex items-center mb-4">
        <div style={{ height: 'fit-content' }}>
          <FormattedMessage id="signingFormEndSemesterDateLabel" />:&nbsp;
        </div>
        <div>
          <DateOnlyInput
            required
            disabled={disabled}
            value={form.endSemesterCompletedDate}
            setValue={value => handleChange('endSemesterCompletedDate', value)}
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.attendancePassed}
        label={formatMessage({ id: 'signing-form-approved-attendance' })}
        onChange={checked => handleChange('attendancePassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.workRequirementsPassed}
        label={formatMessage({ id: 'signing-form-approved-work-requirements' })}
        onChange={checked => handleChange('workRequirementsPassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.specificCompetenceAreasPassed}
        label={formatMessage({ id: 'signing-form-approved-specific-competencies' })}
        onChange={checked => handleChange('specificCompetenceAreasPassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.overallPassed}
        label={formatMessage({ id: 'signing-form-period-graded' })}
        onChange={checked => handleChange('overallPassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'signing-form-passed' })}
        uncheckedOptionLabel={formatMessage({ id: 'signing-form-failed' })}
      />
    </>
  );
}
