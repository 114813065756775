import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useLoggedInUser from 'src/hooks/user/useLoggedInUser';

export default function RedirectToJoinClassPage() {
  const loggedInUser = useLoggedInUser();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/student/join-class') return;
    if (!loggedInUser) return;
    if (loggedInUser.isLoading) return;

    const hasClass = !!loggedInUser.data?.studentReferences?.classId;

    if (!hasClass) history.push('/student/join-class');
  }, [loggedInUser.data, loggedInUser.isLoading]);

  return null;
}
