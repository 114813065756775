import { useState } from 'react';
import { HttpResponse, useHttpClient } from 'src/context/HttpClientContext';
import { AdvisorDetails, Class, SimpleUser, UsersForClass } from 'src/models/classes';
import { createContainer } from 'unstated-next';
import ToastsContext from 'src/hooks/useToasts';

function useClasses() {
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const [loadingFinished, setLoadingFinished] = useState<boolean>(false);

  const [classes, setClasses] = useState<Array<Class> |undefined>();
  const [classesLoaded, setClassesLoaded] = useState<boolean>(false);

  const [currentClass, setCurrentClass] = useState<Class>();
  const [currentClassLoaded, setCurrentClassLoaded] = useState<boolean>(false);

  const [usersForClass, setUsersForClass] = useState<UsersForClass |undefined>();
  const [usersForClassLoaded, setUsersForClassLoaded] = useState<boolean>(false);

  const [supervisorClasses, setSupervisorClasses] = useState<Array<Class> |undefined>();
  const [supervisorClassesLoaded, setSupervisorClassesLoaded] = useState<boolean>(false);

  const [classStudents, setClassStudents] = useState<Array<SimpleUser>>();
  const [classStudentsLoaded, setClassStudentsLoaded] = useState<boolean>(false);

  const [myStudents, setMyStudents] = useState<Array<SimpleUser>>();
  const [myStudentsLoaded, setMyStudentsLoaded] = useState<boolean>(false);

  const [classesError, setClassesError] = useState<HttpResponse<any> | undefined>();
  const [supervisorStudentsError, setSupervisorStudentsError] = useState<HttpResponse<any> | undefined>();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const findClassesInSemester = (semesterId: number) => classes?.filter(_class => _class.periodId === semesterId);

  const { get, post, put, patch, ...httpClient } = useHttpClient();

  const handleSetClassStudents = (students: Array<any>) => {
    setClassStudents(students);
    setClassStudentsLoaded(true);
  };
  const handleSetClasses = (_classes: Array<Class>) => {
    setClasses(_classes);
    setClassesLoaded(true);
  };
  const handleSetMyStudents = (students: Array<SimpleUser>) => {
    setMyStudents(students);
    setMyStudentsLoaded(true);
  };
  const handleSetCurrentClass = (_class: Class) => {
    setCurrentClass(_class);
    setCurrentClassLoaded(true);
  };
  const handleSetUsersForClass = (users: UsersForClass) => {
    setUsersForClass(users);
    setUsersForClassLoaded(true);
  };
  const handleSetSupervisorClasses = (classesForAdvisor: Array<Class>) => {
    setSupervisorClasses(classesForAdvisor);
    setSupervisorClassesLoaded(true);
  };

  const getMyStudents = (classId: number) => get({ url: `api/classes/${classId}/students/mine` }).then(res => handleSetMyStudents(res.data as Array<SimpleUser>)).catch(error => setClassesError(error));
  const assignStudentsToMe = (classId: number, studentIds: Array<number>) => post({ url: `api/classes/${classId}/students/mine`, data: studentIds }).then(() => {
    setShowSuccessToast(true);
    getMyStudents(classId);
  }).catch(error => setErrorToast(error));

  const addSubjectsToClass = async (newClasses: Array<Partial<Class>>) => {
    setIsSaving(true);
    await newClasses.forEach(async newClass => {
      await post({ url: 'api/classes', data: newClass }).then(() => {
      })
        .catch(error => setErrorToast(error));
    });
    getClasses();
    setShowSuccessToast(true);
    setIsSaving(false);
  };
  const updateClass = (newClass: Class) => put({ url: 'api/classes', data: newClass }).then(() => {
    setShowSuccessToast(true);
    setCurrentClass(newClass);
  }).catch(error => setErrorToast(error));

  const addFormsToClass = async (formIds: Array<number>, classId: number) => {
    setIsSaving(true);
    await patch({ url: `api/classes/${classId}/forms`, data: formIds as Array<number> }).then(() => {
      setShowSuccessToast(true);
      getCurrentClass(classId);
    }).catch(error => setErrorToast(error)).finally(() => setIsSaving(false));
  };

  const getClasses = () => get({ url: 'api/classes' })
    .then(response => {
      handleSetClasses(response.data as Array<Class>);
    }).catch(error => {
      setClassesError(error);
    });

  const updateCurrentClass = (classId: number) => {
    setCurrentClass(undefined);
    get({ url: `api/classes/${classId}` })
      .then(response => {
        handleSetCurrentClass(response.data as Class);
      }).catch(error => {
        setClassesError(error);
      });
  };

  const getCurrentClass = (classId: number) => get({ url: `api/classes/${classId}` })
    .then(response => {
      handleSetCurrentClass(response.data as Class);
    }).catch(error => {
      setClassesError(error);
    });

  const getSupervisorClasses = async (classIds: Array<number>) => {
    async function fetchClass(id: number) {
      const result: HttpResponse<Class> = await get({ url: `api/classes/${id}` });
      return result.data;
    }

    try {
      setSupervisorClasses(undefined);
      setSupervisorClassesLoaded(false);

      const fetchedClasses = await Promise.all(classIds.map(id => fetchClass(id)));
      const filteredClasses = fetchedClasses.filter(c => c) as Class[];

      setSupervisorClassesLoaded(true);
      setSupervisorClasses(filteredClasses);
    } catch (error: any) {
      setSupervisorStudentsError(error);
      setSupervisorClasses(undefined);
      setSupervisorClassesLoaded(true);
    }
  };
  const getUsersForClass = (classId: number) => get({ url: `api/classes/${classId}/users` })
    .then(response => {
      handleSetUsersForClass(response.data as UsersForClass);
    }).catch(error => {
      setClassesError(error);
    });

  const addSupervisorToClass = (classId: number, advisorDetails: AdvisorDetails) => {
    setIsSaving(true);
    return post({ url: `api/classes/${classId}/advisors`, data: advisorDetails }).then(() => {
      setShowSuccessToast(true);
      getUsersForClass(classId);
    }).catch(err => setErrorToast(err))
      .finally(() => setIsSaving(false));
  };

  const joinClassAsTeacher = (classId: number, getUser: () => void) => {
    setIsSaving(true);
    return post({ url: `api/classes/${classId}/teachers/me` })
      .then(() => setShowSuccessToast(true))
      .catch(error => setErrorToast(error))
      .finally(() => {
        setIsSaving(false);
        getUser();
      });
  };
  const leaveClassAsTeacher = (classId: number, getUser: () => void) => {
    setIsSaving(true);
    httpClient.delete({ url: `api/classes/${classId}/teachers/me` })
      .then(() => setShowSuccessToast(true))
      .catch(error => {
        setErrorToast(error);
      }).finally(() => {
        setIsSaving(false);
        getUser();
      });
  };

  const setAdvisorForStudent = async (classId: number, studentUserId: number, advisorUserId: number) => {
    setIsSaving(true);
    await get({ url: `api/classes/${classId}/students/${studentUserId}/advisor/${advisorUserId}` }).then(() => setShowSuccessToast(true)).catch(err => setErrorToast(err)).finally(() => setIsSaving(false));
  };
  const getClassStudents = (classId: number) => get({ url: `api/classes/${classId}/students` }).then(res => handleSetClassStudents(res.data)).catch(err => setSupervisorStudentsError(err));

  return {
    handleSetClasses,
    classes,
    classesLoaded,
    classesError,
    findClassesInSemester,
    getClasses,
    getCurrentClass,
    currentClassLoaded,
    currentClass,
    usersForClass,
    usersForClassLoaded,
    getUsersForClass,
    handleSetCurrentClass,
    handleSetUsersForClass,
    updateClass,
    addSupervisorToClass,
    getMyStudents,
    myStudents,
    addFormsToClass,
    myStudentsLoaded,
    handleSetMyStudents,
    joinClassAsTeacher,
    leaveClassAsTeacher,
    getSupervisorClasses,
    supervisorClasses,
    supervisorClassesLoaded,
    handleSetSupervisorClasses,
    isSaving,
    setIsSaving,
    addSubjectsToClass,
    assignStudentsToMe,
    setAdvisorForStudent,
    getClassStudents,
    classStudents,
    handleSetClassStudents,
    supervisorStudentsError,
    classStudentsLoaded,
    loadingFinished,
    setLoadingFinished,
    updateCurrentClass,
  };
}
const ClassesContext = createContainer(useClasses);

export default ClassesContext;
