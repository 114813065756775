import { Input } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import DateOnly from 'src/models/common/DateOnly';
import SigningForm from 'src/models/SigningForm';
import DateOnlyInput from '../_common/DateOnlyInput/DateOnlyInput';
import RadioButtonRow from './RadioButtonRow';

interface FormFieldsProps {
  form: SigningForm;
  setForm: (form: SigningForm) => void;
  disabled: boolean;
}

export default function MidSemesterFormFields({ form, setForm, disabled }: FormFieldsProps) {
  const { formatMessage } = useIntl();

  function handleChange(fieldName: string, value: boolean | Date) {
    setForm({ ...form, [fieldName]: value });
  }

  return (
    <>
      <h3 className="border-b-2" style={{ borderColor: 'black' }}>
        <FormattedMessage id="mid-semester" />
      </h3>
      <div className="flex items-center mb-4">
        <div style={{ height: 'fit-content' }}>
          <FormattedMessage id="signingFormMidSemesterDateLabel" />:&nbsp;
        </div>
        <div>
          <DateOnlyInput
            required
            disabled={disabled}
            value={form.midSemesterCompletedDate}
            setValue={value => handleChange('midSemesterCompletedDate', value)}
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.midSemesterStudentHasGivenFeedback}
        label="Studenten har gjort veileder kjent med tilbakemeldinger fra tidligere praksisperioder (gjelder ikke første studieår)"
        onChange={checked => handleChange('midSemesterStudentHasGivenFeedback', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.midSemesterSpecificCompetenceAreasBegun}
        label="Arbeid med spesifikke kompetanseområder er påbegynt (dersom det er krysset av for nei skal varsel om mulig ikke bestått vurderes)"
        onChange={checked => handleChange('midSemesterSpecificCompetenceAreasBegun', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
    </>
  );
}
