import { View } from '@react-pdf/renderer';
import StudentPassWarning from 'src/models/StudentPassWarning';
import CommentText from './CommentText';

interface WarningDisplayProps {
  warning: StudentPassWarning | null;
  studentName?: string;
  formatMessageWrapper: (id: string) => string; // Workaround: Context API does not work for react-pdf: https://github.com/diegomura/react-pdf/issues/522
}

export default function WarningDisplay({ warning, studentName, formatMessageWrapper }: WarningDisplayProps) {
  const getWarningTitle = () => (warning ? `${studentName} ${formatMessageWrapper('userHasWarning')}` : `${studentName} ${formatMessageWrapper('userHasNotWarning')}`);

  if (!studentName) return null;

  return (
    <View wrap={false}>
      <CommentText text={getWarningTitle()} />
      {warning && (
        <>
          <CommentText label={formatMessageWrapper('comment')} text={warning.comment} />
          <CommentText label={formatMessageWrapper('requirement')} text={warning.passRequirement} />
          <CommentText label={formatMessageWrapper('requirementSummary')} text={warning.passRequirementSummary} />
        </>
      )}
    </View>
  );
}
