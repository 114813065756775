import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Input } from '@windmill/react-ui';
import classnames from 'classnames';
import { RoleTypes } from 'src/models/roles';
import FormBadges from '../../../FormBadges/FormBadges';
import GradingOptionLabel, { LabelType } from './GradingOptionLabel';

export interface FormQuestionGradingProps {
  labels: string[];
  activeColor?: string;
  supervisorGrade?: number;
  teacherMidTermGrade?: number;
  studentGrade?: number;
  otherSemesterExpectation?: number;
  currentSemesterExpectation?: number;
  teacherGrade?: number;
  answer?: string | number | string[] | undefined;
  setAnswer: (value: any) => void;
  activeRole?: RoleTypes;
  isMidSemester?: boolean;
}

export const FormQuestionGradingComponent: FC<FormQuestionGradingProps> = ({
  labels,
  answer,
  setAnswer,
  otherSemesterExpectation,
  currentSemesterExpectation,
  teacherGrade,
  studentGrade,
  supervisorGrade,
  teacherMidTermGrade,
  activeColor,
  activeRole,
  isMidSemester,
}: FormQuestionGradingProps) => {
  const [uuid] = useState(uuidv4());

  const midTermExcpectation = isMidSemester ? currentSemesterExpectation : otherSemesterExpectation;
  const endTermExcpectation = !isMidSemester ? currentSemesterExpectation : otherSemesterExpectation;

  const getGradingOptionLabelType = (index: number) => {
    const isMidSemesterExpectation = index === midTermExcpectation;
    const isEndSemesterExpectation = index === endTermExcpectation;

    if (isMidSemesterExpectation && isEndSemesterExpectation) return LabelType.EndSemesterExpectation;
    if (isMidSemesterExpectation) return LabelType.MidSemesterExpectation;
    if (isEndSemesterExpectation) return LabelType.EndSemesterExpectation;
    return LabelType.Default;
  };

  const getRadioButtonInputId = (index: number) => `radio-button-input-${index}-${uuid}`;
  return (
    <>
      <div className={`grid grid-cols-${labels.length} gap-5 text-center justify-items-center justify-center p-4 items-end`}>
        {labels.map((value, index) => (
          <GradingOptionLabel
            label={value}
            showMidTermLabel={teacherMidTermGrade === index && !isMidSemester}
            type={getGradingOptionLabelType(index)}
            inputElementId={getRadioButtonInputId(index)}
            key={value}
          />
        ))}
      </div>
      <div className="border-0 ring-0 ring-white">
        <div>
          <div className="flex flex-col justify-evenly">
            <div className={`grid grid-cols-${labels.length} gap- 5 justify-items-center justify-center`}>
              {labels.map((label, index) => (
                <div className="radio-buttons flex flex-col items-center" key={`selection-${label}-${uuid}`}>
                  <Input
                    checked={answer !== undefined && answer === index}
                    type="radio"
                    data-testid={`selection-${label}`}
                    css=""
                    className={classnames({
                    }, `border-2 text-${activeColor} focus:ring-${activeColor}`)}
                    onChange={() => { setAnswer(index); }}
                    aria-label={label}
                    id={getRadioButtonInputId(index)}
                  />
                  <FormBadges
                    studentGrade={studentGrade}
                    supervisorGrade={supervisorGrade}
                    currentValue={index}
                    activeRole={activeRole}
                    teacherGrade={teacherGrade}
                    answer={Number(answer)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormQuestionGradingComponent;
