import { useState } from 'react';
import { HttpResponse, useHttpClient } from 'src/context/HttpClientContext';
import { createContainer } from 'unstated-next';
import { AnswerSet } from 'src/components/FormComponent/FormAnswers';

interface SemesterAnswers {
    expectationsAnswerSetId?: number,
    student: Partial<AnswerSet>,
    teacher: Partial<AnswerSet>,
    advisor: Partial<AnswerSet>,
}

export interface GradedAnswerSetIds {
    midSemester?: SemesterAnswers
    endSemester: SemesterAnswers
}

function useAnswers() {
  const [gradedAnswerSetIds, setGradedAnswerSetIds] = useState<GradedAnswerSetIds>();
  const [gradedAnswerSetIdsLoaded, setGradedAnswerSetIdsLoaded] = useState<boolean>(false);
  const [gradedAnswerSetIdsError, setGradedAnswerSetIdsError] = useState<HttpResponse<any> | undefined>();

  const [getAnswerSetError, setGetAnswerSetError] = useState<HttpResponse<any> | undefined>();

  const [relevantAnswersLoaded, setRelevantAnswersLoaded] = useState<boolean>(false);
  const [midTermExpectations, setMidTermExpectations] = useState<AnswerSet>();
  const [endTermExpectations, setEndTermExpectations] = useState<AnswerSet>();
  const [adminAnswers, setAdminAnswers] = useState<AnswerSet>();
  const [studentAnswers, setStudentAnswers] = useState<AnswerSet>();
  const [supervisorAnswers, setSupervisorAnswers] = useState<AnswerSet>();
  const [teacherAnswers, setTeacherAnswers] = useState<AnswerSet>();
  const [teacherMidTermAnswers, setTeacherMidTermAnswers] = useState<AnswerSet>();
  const [supervisorMidTermAnswers, setSupervisorMidTermAnswers] = useState<AnswerSet>();
  const [studentMidTermAnswers, setStudentMidTermAnswers] = useState<AnswerSet>();

  const { get } = useHttpClient();

  const getGradedAnswerSetIds = (classId: number, studentUserId: number, formid: number) => {
    setGradedAnswerSetIdsLoaded(false);
    setRelevantAnswersLoaded(false);
    get({ url: `api/classes/${classId}/students/${studentUserId}/forms/${formid}` })
      .then(response => {
        handleSetGradedAnswerSetIds(response.data as GradedAnswerSetIds);
      })
      .catch(error => setGradedAnswerSetIdsError(error))
      .finally(() => {
        setRelevantAnswersLoaded(true);
        setGradedAnswerSetIdsLoaded(true);
      });
  };
  const getAnswers = (answerSetId: number) => get({ url: `api/answers/${answerSetId}` });

  const fetchAllAnswers = (classId: number, studentUserId: number, formid: number, isMidTerm: boolean) => {
    get({ url: `api/classes/${classId}/students/${studentUserId}/forms/${formid}/full` })
      .then(response => {
        const { data } = response;
        if (isMidTerm) {
          setStudentAnswers(data.midSemester?.student as AnswerSet);
          setSupervisorAnswers(data.midSemester?.advisor as AnswerSet);
          setTeacherAnswers(data.midSemester?.teacher as AnswerSet);
        } else {
          setStudentAnswers(data.endSemester?.student as AnswerSet);
          setSupervisorAnswers(data.endSemester?.advisor as AnswerSet);
          setTeacherAnswers(data.endSemester?.teacher as AnswerSet);
        }
        setTeacherMidTermAnswers(data.midSemester?.teacher as AnswerSet);
        setMidTermExpectations(data.midSemester?.expectations as AnswerSet);
        setEndTermExpectations(data.endSemester?.expectations as AnswerSet);
        setSupervisorMidTermAnswers(data.midSemester?.advisor as AnswerSet);
        setStudentMidTermAnswers(data.midSemester?.student as AnswerSet);
      })
      .catch(error => setGetAnswerSetError(error))
      .finally(() => {
        setRelevantAnswersLoaded(true);
      });
  };

  const getAdminAnswers = (classId: number, formId: number, midTermEvaluation: boolean) => {
    setRelevantAnswersLoaded(false);
    const searchParams = new URLSearchParams();
    searchParams.append('isMidSemester', midTermEvaluation.toString());
    get({ url: `api/classes/${classId}/forms/${formId}/expectations?${searchParams}` })
      .then(res => setAdminAnswers(res.data as AnswerSet))
      .catch(error => setGetAnswerSetError(error))
      .finally(() => setRelevantAnswersLoaded(true));
  };

  const handleSetGradedAnswerSetIds = (_gradedAnswerSetIds: GradedAnswerSetIds) => {
    setGradedAnswerSetIds(_gradedAnswerSetIds);
    setGradedAnswerSetIdsLoaded(true);
  };

  const getRelevantAnswers = async (classId: number, studentUserId: number, formid: number, isMidTerm: boolean) => {
    setRelevantAnswersLoaded(false);
    fetchAllAnswers(+classId, +studentUserId, formid, isMidTerm);
  };

  const resetAnswersContext = () => {
    setMidTermExpectations(undefined);
    setStudentAnswers(undefined);
    setSupervisorAnswers(undefined);
    setTeacherAnswers(undefined);
    setTeacherMidTermAnswers(undefined);
    setSupervisorMidTermAnswers(undefined);
    setStudentMidTermAnswers(undefined);
    setEndTermExpectations(undefined);
  };

  return {
    gradedAnswerSetIds,
    gradedAnswerSetIdsLoaded,
    gradedAnswerSetIdsError,
    getGradedAnswerSetIds,
    handleSetGradedAnswerSetIds,
    studentAnswers,
    adminAnswers,
    supervisorAnswers,
    teacherAnswers,
    getAdminAnswers,
    setStudentAnswers,
    getAnswerSetError,
    getRelevantAnswers,
    relevantAnswersLoaded,
    setRelevantAnswersLoaded,
    setGradedAnswerSetIdsLoaded,
    getAnswers,
    midTermExpectations,
    endTermExpectations,
    teacherMidTermAnswers,
    supervisorMidTermAnswers,
    studentMidTermAnswers,
    fetchAllAnswers,
    resetAnswersContext,
  };
}

const AnswersContext = createContainer(useAnswers);

export default AnswersContext;
