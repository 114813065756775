import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import { Form } from 'src/models/form';
import StudentPassWarning from 'src/models/StudentPassWarning';
import SigningForm from 'src/models/SigningForm';
import TableHeader from './TableHeader';
import TableBody from './TableBody/index';
import FormAnswers from '../../../../../components/FormComponent/FormAnswers';

interface TableForPDFProps{
  form: Form,
  teacherEndTermAnswers: FormAnswers | undefined,
  teacherMidTermAnswers: FormAnswers | undefined,
  midTermExpectations: FormAnswers | undefined,
  endTermExpectations: FormAnswers | undefined,
  studentComment: string | undefined,
  supervisorComment: string | undefined,
  teacherComment: string | undefined,
  studentName: string | undefined,
  studentWarning: StudentPassWarning | null,
  signingForm: SigningForm | null,
  formatMessageWrapper: (id: string) => string
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    width: '100vw',
  },
  innerPage: {
    padding: 30,
    paddingTop: 40,
    width: '100%',
  },
  titleContainer: {
    flexDirection: 'column',
    marginBottom: 20,
    fontWeigt: 'bold',
  },
  reportTitle: {
    fontSize: 20,
    textTransform: 'uppercase',
  },
  mainContainer: {
    border: '1px solid #c2d9ff',
  },
});

const TableForPDF = ({
  form,
  studentName,
  teacherEndTermAnswers,
  teacherMidTermAnswers,
  midTermExpectations,
  endTermExpectations,
  studentComment,
  supervisorComment,
  teacherComment,
  studentWarning,
  signingForm,
  formatMessageWrapper,
}: TableForPDFProps) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.innerPage}>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{studentName}</Text>
          <Text style={styles.reportTitle}>{form.name}</Text>
        </View>
        <View style={styles.mainContainer}>
          <TableHeader />
          <TableBody
            form={form}
            teacherMidTermAnswers={teacherMidTermAnswers}
            teacherEndTermAnswers={teacherEndTermAnswers}
            midTermExpectations={midTermExpectations}
            endTermExpectations={endTermExpectations}
            studentComment={studentComment}
            supervisorComment={supervisorComment}
            teacherComment={teacherComment}
            studentWarning={studentWarning}
            studentName={studentName}
            signingForm={signingForm}
            formatMessageWrapper={formatMessageWrapper}
          />
        </View>
      </View>
    </Page>
  </Document>
);

export default TableForPDF;
