import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Class } from 'src/models/classes';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';

interface StudentWarningModalContentProps {
  commentSlot: ReactNode | ReactNode[];
  passRequirementSlot: ReactNode | ReactNode[];
  passRequirementSummarySlot: ReactNode | ReactNode[];
  class: Class;
  student: User;
  supervisor?: User;
  teacher?: User;
  warning?: StudentPassWarning;
}

export default function StudentWarningModalContent({ commentSlot,
  passRequirementSlot,
  passRequirementSummarySlot,
  class: _class,
  student,
  supervisor,
  teacher,
  warning }: StudentWarningModalContentProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <p className="mb-1">
        <strong>{formatMessage({ id: 'class' })}: </strong> {_class.subject?.subjectCode} {_class.subject?.name}
      </p>
      <p className="mb-1">
        <strong>{formatMessage({ id: 'Student' })}: </strong> {student.displayName}
      </p>
      <p className="mb-1">
        <strong>{formatMessage({ id: 'Advisor' })}: </strong> {supervisor?.displayName}
      </p>
      <p className="mb-1">
        <strong>{formatMessage({ id: 'Teacher' })}: </strong> {teacher?.displayName}
      </p>
      {warning && (
        <p className="mt-1">
          <strong>Dato: </strong> {warning.createdAt.toLocaleDateString()}
        </p>
      )}
      <p className="mb-8 mt-4">
        {formatMessage({ id: 'sendStudentWarningModalHeaderSectionText' })}
      </p>
      <div className="mb-8">
        <p className="font-bold mb-2">{formatMessage({ id: 'sendStudentWarningModalCommentLabel' })}</p>
        <p className="mb-2">{formatMessage({ id: 'sendStudentWarningModalCommentInfoText' })}</p>
        <ul className="list-disc pl-6 mb-4" style={{ listStyle: 'initial' }}>
          <li>{formatMessage({ id: 'sendStudentWarningModalCommentInfoTextBulletPoint1' })}</li>
          <li>{formatMessage({ id: 'sendStudentWarningModalCommentInfoTextBulletPoint2' })}</li>
        </ul>
        {commentSlot}
      </div>
      <div className="mb-8">
        <p className="font-bold mb-2">{formatMessage({ id: 'sendStudentWarningModalRequirementsLabel' })}</p>
        <p className="mb-2">{formatMessage({ id: 'sendStudentWarningModalRequirementsInfoTextTop' })}</p>
        <ul className="list-disc pl-6 mb-4" style={{ listStyle: 'initial' }}>
          <li>{formatMessage({ id: 'sendStudentWarningModalRequirementsInfoTextTopBulletPoint1' })}</li>
          <li>{formatMessage({ id: 'sendStudentWarningModalRequirementsInfoTextTopBulletPoint2' })}</li>
        </ul>
        {passRequirementSlot}
      </div>
      <p className="mb-2">{formatMessage({ id: 'sendStudentWarningModalRequirementsInfoTextBottom' })}</p>
      {passRequirementSummarySlot}
    </>
  );
}
