import { Alert } from '@windmill/react-ui';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStudentPassWarning from 'src/hooks/useStudentPassWarning';
import { Class } from 'src/models/classes';
import { User } from 'src/models/user';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import ShowWarningModal from '../StudentPage/StudentWarningSection/ShowModal';
import Button from '../_common/Button/Button';

interface WarningSectionProps {
  student: User;
  supervisor?: User;
  teacher?: User;
  class: Class;
  className?: string;
}

export default function WarningSection({ class: _class, student, className, supervisor, teacher }: WarningSectionProps) {
  const { formatMessage } = useIntl();
  const [modalOpen, setIsModalOpen] = useState(false);

  const query = useStudentPassWarning(_class.id, student.id);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (query.isLoading || !query.data) return null;
  if (query.isError) return <ApiErrorAlert error={query.error} message={formatMessage({ id: 'signingFormWarningErrorMessage' })} />;

  const warning = query.data;

  const studentHasAccepted = !!warning.studentConfirmedWarningAt;

  return (
    <div className={className}>
      <div className="font-semibold text-center mt-8">
        <FormattedMessage id="signing-form-warning-given" values={{ date: warning.createdAt.toLocaleDateString() }} />
      </div>
      {studentHasAccepted ? (
        <Alert type="warning" className="mt-2">
          <FormattedMessage
            id="signing-form-warning-accepted"
            values={{ studentName: student?.displayName, date: warning.studentConfirmedWarningAt?.toLocaleDateString() }}
          />
        </Alert>
      ) : (
        <Alert type="warning" className="mt-2 text-center" data-testid="not-confirmed-alert">
          <FormattedMessage id="signing-form-warning-not-accepted" values={{ studentName: student?.displayName }} />
        </Alert>
      )}

      <Button
        className="bg-secondary w-full mt-4 text-customBrown"
        data-testid="show-warning-button"
        onClick={openModal}
      >{formatMessage({ id: 'showWarning' })}
      </Button>
      <ShowWarningModal
        isOpen={modalOpen}
        close={closeModal}
        class={_class}
        student={student}
        warning={warning}
        supervisor={supervisor}
        teacher={teacher}
      />
    </div>
  );
}
