import { StyleSheet, Text, View } from '@react-pdf/renderer';

interface CommentTextProps {
    label?: string,
    text: string
}

const styles = StyleSheet.create({
  text: {
    padding: '4px',
    fontSize: 12,
    fontWeight: 'light',
  },
});

export default function CommentText({ label, text }: CommentTextProps) {
  return (
    <View wrap={false}>
      <Text style={styles.text}>
        {label ? `${label}: ${text}` : text}
      </Text>
    </View>
  );
}
