import { useParams } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import { RoleTypes } from 'src/models/roles';
import Spinner from '../_common/Spinner/Spinner';
import PageHeader from '../_common/PageHeader/PageHeader';
import SupervisorSelect from './SupervisorSelect';
import { useStudentPageQuery } from './queries';
import ApiErrorAlert from '../_common/ApiErrorAlert/ApiErrorAlert';
import StudentWarningSection from './StudentWarningSection/StudentWarningSection';
import SigningFormSection from './SigningFormSection/SigningFormSection';
import FormsSection from './FormsSection';

const StudentPage = ({ activeRole }: { activeRole?: RoleTypes }) => {
  const { classId, userId } = useParams<{ classId: string, userId: string }>();

  const query = useStudentPageQuery(+classId, +userId);

  if (query.isLoading) return <Spinner />;
  if (query.isError) return <ApiErrorAlert error={query.error} message="" />;

  const { class: _class, student, warning, supervisor, teacher, signingForm } = query.data;

  return (
    <>
      <PageHeader title={query.data.student.displayName}>
        <div className="flex justify-center items-center text-darkerGray">{query.data.class.subject?.subjectCode}
          <BsDot />
          {query.data.class.period?.name}
        </div>
      </PageHeader>
      {activeRole === RoleTypes.Teacher && (
        <>
          <SupervisorSelect classId={+classId} student={query.data.student} />
          <StudentWarningSection
            class={_class}
            student={student}
            warning={warning}
            supervisor={supervisor}
            teacher={teacher}
          />
        </>
      )}
      <SigningFormSection signingForm={signingForm} showCreateButton={activeRole === RoleTypes.Teacher} />
      <FormsSection class={_class} signingForm={signingForm!} />
    </>
  );
};

export default StudentPage;
