import { Alert, Dropdown, DropdownItem } from '@windmill/react-ui';
import { useState } from 'react';
import { VscEllipsis } from 'react-icons/vsc';
import { FormattedMessage, useIntl } from 'react-intl';
import { Class } from 'src/models/classes';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';
import DeleteModal from './DeleteModal';
import ShowModal from './ShowModal';

interface StudentWarningAlertProps {
  class: Class;
  student: User;
  warning: StudentPassWarning;
  supervisor?: User;
  teacher?: User;
}

export default function StudentWarningAlert({ class: _class, student, warning, supervisor, teacher }: StudentWarningAlertProps) {
  const { formatMessage } = useIntl();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showWarningModalOpen, setShowWarningModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <div className="font-semibold text-center mt-8">
        <FormattedMessage id="signing-form-warning-given" values={{ date: warning.createdAt.toLocaleDateString() }} />
      </div>
      <div className="relative">
        <Alert type={warning.studentConfirmedWarningAt ? 'success' : 'warning'} className="mt-2 text-center relative">
          <div className="flex">
            <div className="text-left" style={{ flexGrow: 1 }}>
              {warning.studentConfirmedWarningAt ? (
                <FormattedMessage
                  id="signing-form-warning-accepted"
                  values={{ studentName: student.displayName, date: warning.studentConfirmedWarningAt.toLocaleDateString() }}
                />
              ) : (
                <FormattedMessage
                  id="signing-form-warning-not-accepted"
                  values={{ studentName: student.displayName }}
                />
              )}
            </div>
            <button onClick={() => setDropdownOpen(true)} aria-label={formatMessage({ id: 'showMore' })} data-testid="open-dropdown-button">
              <VscEllipsis className="w-6 h-6" />
            </button>
          </div>
        </Alert>
        <Dropdown
          isOpen={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          align="right"
          style={{ width: '8rem' }}
          data-testid="dropdown"
        >
          <DropdownItem onClick={() => setShowWarningModalOpen(true)} data-testid="show-warning-button">
            <FormattedMessage id="show-warning" />
          </DropdownItem>
          <DropdownItem onClick={() => setDeleteModalOpen(true)} data-testid="delete-warning-button">
            <FormattedMessage id="delete-warning" />
          </DropdownItem>
        </Dropdown>
      </div>
      <ShowModal
        isOpen={showWarningModalOpen}
        close={() => setShowWarningModalOpen(false)}
        class={_class}
        student={student}
        warning={warning}
        supervisor={supervisor}
        teacher={teacher}
      />
      <DeleteModal isOpen={deleteModalOpen} close={() => setDeleteModalOpen(false)} classId={_class.id} studentUserId={student.id} />
    </>
  );
}
