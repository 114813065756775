import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import ResponsAlert from 'src/components/_common/Alert/ResponsAlert';
import { useIntl } from 'react-intl';
import Spinner from 'src/components/_common/Spinner/Spinner';
import JoinClassForm from './JoinClassForm';
import { useMatchingFeideClassesQuery } from './queries';

export default function JoinClassPage() {
  const matchingFeideClasses = useMatchingFeideClassesQuery();
  const { formatMessage } = useIntl();

  if (matchingFeideClasses.isLoading) {
    return <div data-testid="loading-container"><Spinner /></div>;
  }

  if (matchingFeideClasses.isError) {
    return (
      <ApiErrorAlert
        error={matchingFeideClasses.error}
        message={formatMessage({ id: 'studentJoinClassFetchClassErrorMessage' })}
      />
    );
  }

  if (matchingFeideClasses.data.length === 0) {
    return (
      <div data-testid="no-classes-container">
        <ResponsAlert
          type="warning"
          title={formatMessage({ id: 'studentJoinClassNoClassesTitle' })}
          message={formatMessage({ id: 'studentJoinClassNoClassesMessage' })}
        />
      </div>
    );
  }

  return (
    <>
      <h1>
        {formatMessage({ id: 'studentJoinClassPageTitle' })}
      </h1>
      <JoinClassForm classes={matchingFeideClasses.data} />
    </>
  );
}
