import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '15vh',
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    flex: 9,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    borderRight: '1px solid #c2d9ff',
  },
  labelText: {
    fontSize: 12,
    transform: 'rotate(90deg)',
  },
  rotatedTextContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  hasBorder: {
    borderRight: '1px solid #c2d9ff',
  },
  semesterHeader: {
    flex: 1,
  },
  labelTextContainer: {
    flexDirection: 'row',
    flex: 12,
    padding: 0,
  },
});
const defaultLabels = ['Selvstendig', 'Delvis Selvstendig', 'Veiledet', 'Assistert', 'Uselvstendig', 'Ikke aktuelt'];

const TableHeader = () => (
  <View style={styles.container}>
    <View style={styles.description}>
      <Text>Vurderingskriterier</Text>
    </View>
    <View style={[styles.semesterHeader, styles.hasBorder]} />
    <View style={styles.labelTextContainer}>
      {defaultLabels.map(label => <View key={label} style={[styles.rotatedTextContainer, styles.hasBorder]}><Text style={styles.labelText}>{label}</Text></View>)}
    </View>
  </View>
);

export default TableHeader;
